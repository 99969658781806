<template>
  <b-card-code title="Graphics">
    <b-row>
        <b-col cols="12" md="4">
            <b-card
                v-if="data"
                no-body
            >
                <b-card-header class="align-items-baseline">
                <div>
                    <b-card-title>Sales</b-card-title>
                    <b-card-text class="mb-0">
                    Last 6 months
                    </b-card-text>
                </div>
                <b-dropdown
                    variant="link"
                    no-caret
                    right
                    class="chart-dropdown"
                    toggle-class="p-0"
                >
                    <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                        class="text-body cursor-pointer"
                    />
                    </template>
                    <b-dropdown-item href="#">
                    Last 28 Days
                    </b-dropdown-item>
                    <b-dropdown-item href="#">
                    Last Month
                    </b-dropdown-item>
                    <b-dropdown-item href="#">
                    Last Year
                    </b-dropdown-item>
                </b-dropdown>
                </b-card-header>

                <b-card-body>
                <div class="d-inline-flex align-items-center mr-2">
                    <feather-icon
                    icon="CircleIcon"
                    class="text-danger mr-50"
                    />
                    <span>Sales</span>
                </div>
                <div class="d-inline-flex align-items-center">
                    <feather-icon
                    icon="CircleIcon"
                    class="text-primary mr-50"
                    />
                    <span>Visits</span>
                </div>

                <!-- apex chart -->
                <vue-apex-charts
                    type="radar"
                    height="275"
                    :options="statisticsRadar.chartOptions"
                    :series="data.series"
                />
                </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="12" md="8">
            <b-card
                no-body
                class="card-revenue-budget"
            >
                <b-row class="mx-0">
                <b-col
                    md="8"
                    class="revenue-report-wrapper"
                >
                    <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                        Revenue Report
                    </h4>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center mr-2">
                        <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
                        <span>Earning</span>
                        </div>
                        <div class="d-flex align-items-center">
                        <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                        <span>Expense</span>
                        </div>
                    </div>
                    </div>

                    <!-- chart -->
                    <vue-apex-charts
                    id="revenue-report-chart"
                    type="bar"
                    height="230"
                    :options="revenueReport.chartOptions"
                    :series="revenueReport.series"
                    />
                </b-col>

                <b-col
                    md="4"
                    class="budget-wrapper"
                >

                    <h2 class="mb-25">
                        $25,852
                    </h2>
                    <div class="d-flex justify-content-center">
                        <span class="font-weight-bolder mr-25">Budget:</span>
                        <span>56,800</span>
                    </div>
                    <vue-apex-charts
                    id="budget-chart"
                    type="line"
                    height="80"
                    :options="budgetChart.options"
                    :series="budgetChart.series"
                    />

                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    >
                    Increase Budget
                    </b-button>
                </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="9">
            <b-card no-body>
                <b-card-header>
                <!-- title and subtitle -->
                <div>
                    <b-card-title class="mb-1">
                    Profit by Month
                    </b-card-title>
                </div>
                <!--/ title and subtitle -->

                <!-- datepicker -->
                <div class="d-flex align-items-center">
                    <b-dropdown
                    variant="link"
                    no-caret
                    right
                    class="chart-dropdown"
                    toggle-class="p-0"
                >
                    <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                        class="text-body cursor-pointer"
                    />
                    </template>
                    <b-dropdown-item href="#">
                    Last 28 Days
                    </b-dropdown-item>
                    <b-dropdown-item href="#">
                    Last Month
                    </b-dropdown-item>
                    <b-dropdown-item href="#">
                    Last Year
                    </b-dropdown-item>
                </b-dropdown>
                </div>
                <!-- datepicker -->
                </b-card-header>

                <b-card-body>
                <vue-apex-charts
                    type="area"
                    height="400"
                    :options="componentsChartData.lineAreaChartSpline.chartOptions"
                    :series="componentsChartData.lineAreaChartSpline.series"
                />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="6">
            <b-card no-body>
                <b-card-header class="pb-0">
                <b-card-title>{{ trackerData.title }}</b-card-title>
                <b-dropdown
                    no-caret
                    right
                    text="Last 7 Days"
                    variant="transparent"
                    class="chart-dropdown"
                    toggle-class="p-50"
                    size="sm"
                >
                    <b-dropdown-item
                    v-for="day in trackerData.lastDays"
                    :key="day"
                    >
                    {{ day }}
                    </b-dropdown-item>
                </b-dropdown>
                </b-card-header>
                <!--/ title and dropdown -->

                <b-card-body>
                <b-row>
                    <b-col
                    sm="2"
                    class="d-flex flex-column flex-wrap text-center"
                    >
                    <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                        {{ trackerData.totalTicket }}
                    </h1>
                    <b-card-text>Tickets</b-card-text>
                    </b-col>

                    <!-- chart -->
                    <b-col
                    sm="10"
                    class="d-flex justify-content-center"
                    >

                    <!-- apex chart -->
                    <vue-apex-charts
                        type="radialBar"
                        height="270"
                        :options="supportTrackerRadialBar.chartOptions"
                        :series="supportTrackerRadialBar.series"
                    />
                    </b-col>
                    <!--/ chart -->
                </b-row>

                <!-- chart info -->
                <div class="d-flex justify-content-between">
                    <div class="text-center">
                    <b-card-text class="mb-50">
                        New Tickets
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ trackerData.newTicket }}</span>
                    </div>
                    <div class="text-center">
                    <b-card-text class="mb-50">
                        Open Tickets
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ trackerData.openTicket }}</span>
                    </div>
                    <div class="text-center">
                    <b-card-text class="mb-50">
                        Response Time
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ trackerData.responseTime }}d</span>
                    </div>
                </div>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="12" md="6">
            <b-card>
                <b-row class="pb-50">

                <!-- text and button -->
                <b-col
                    lg="6"
                    order-lg="1"
                    order="2"
                    class="d-flex justify-content-between flex-column"
                >
                    <div class="mt-1 mt-lg-0">
                    <h2 class="font-weight-bolder mb-25">
                        {{ kFormatter(avgData.sessions) }}
                    </h2>
                    <b-card-text class="font-weight-bold mb-2">
                        Avg Sessions
                    </b-card-text>

                    <div class="font-medium-2 mb-1 mb-lg-0">
                        <span class="text-success mr-50">{{ avgData.growth }}</span>
                        <span>vs last 7 days</span>
                    </div>
                    </div>

                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    class="shadow"
                    >
                    View Details
                    </b-button>
                </b-col>

                <!-- dropdown and chart -->
                <b-col
                    lg="6"
                    cols="12"
                    order-lg="2"
                    order="1"
                    class="d-flex justify-content-between flex-column text-right"
                >
                    <div>
                    <b-dropdown
                        text="Last 7 Days"
                        variant="transparent"
                        class="chart-dropdown"
                        left
                        no-caret
                        size="sm"
                    >
                        <b-dropdown-item
                        v-for="day in avgData.lastDays"
                        :key="day"
                        >
                        {{ day }}
                        </b-dropdown-item>
                    </b-dropdown>
                    </div>

                    <!-- apex chart -->
                    <vue-apex-charts
                    type="bar"
                    height="200"
                    :options="salesBar.chartOptions"
                    :series="salesBar.series"
                    />
                </b-col>
                </b-row>
                <hr>

                <!-- progress bar -->
                <b-row class="avg-sessions pt-50">
                <b-col
                    cols="6"
                    class="mb-2"
                >
                    <b-card-text class="mb-50">
                    Goal: ${{ avgData.goal }}
                    </b-card-text>
                    <b-progress
                    value="50"
                    max="100"
                    height="6px"
                    />
                </b-col>
                <b-col
                    cols="6"
                    class="mb-2"
                >
                    <b-card-text class="mb-50">
                    Users: {{ kFormatter(avgData.users) }}
                    </b-card-text>
                    <b-progress
                    value="60"
                    max="100"
                    height="6px"
                    variant="warning"
                    />
                </b-col>
                <b-col cols="6">
                    <b-card-text class="mb-50">
                    Retention: {{ avgData.retention }}%
                    </b-card-text>
                    <b-progress
                    value="70"
                    max="100"
                    height="6px"
                    variant="danger"
                    class="mt-25"
                    />
                </b-col>
                <b-col cols="6">
                    <b-card-text class="mb-50">
                    Duration: {{ avgData.duration }}yr
                    </b-card-text>
                    <b-progress
                    value="90"
                    max="100"
                    variant="success"
                    height="6px"
                    class="mt-25"
                    />
                </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="4">
            <b-card no-body>
                <b-card-header>
                <b-card-title>Sessions By Device</b-card-title>
                <b-dropdown
                    text="Last 7 Days"
                    variant="transparent"
                    class="chart-dropdown "
                    right
                    no-caret
                    toggle-class="p-0 mb-25"
                    size="sm"
                >
                    <b-dropdown-item
                    v-for="day in chartData.lastDays"
                    :key="day"
                    >
                    {{ day }}
                    </b-dropdown-item>
                </b-dropdown>
                </b-card-header>

                <b-card-body>

                <!-- apex chart -->
                <vue-apex-charts
                    type="donut"
                    height="300"
                    class="my-1"
                    :options="sessionsByDeviceDonut.chartOptions"
                    :series="sessionsByDeviceDonut.series"
                />

                <!-- chart info -->
                <div
                    v-for="(data,index) in chartData.chartInfo"
                    :key="data.name"
                    class="d-flex justify-content-between"
                    :class="index === chartData.chartInfo.length - 1 ? 'mb-0':'mb-1'"
                >
                    <div class="series-info d-flex align-items-center">
                    <feather-icon
                        :icon="data.icon"
                        size="16"
                        :class="data.iconColor"
                    />
                    <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span><span>- {{ data.usage }}%</span>
                    </div>
                    <div>
                    <span>{{ data.upDown }}%</span>
                    <feather-icon
                        :icon="data.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
                        :class="data.upDown > 0 ? 'text-success':'text-danger'"
                        class="mb-25 ml-25"
                    />
                    </div>
                </div>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="12" md="4">
            <b-card no-body>
                <b-card-header>
                <b-card-title>Product Orders</b-card-title>
                <b-dropdown
                    text="Last 7 Days"
                    variant="transparent"
                    class="chart-dropdown "
                    right
                    no-caret
                    toggle-class="p-0"
                    size="sm"
                >
                    <b-dropdown-item
                    v-for="day in chartInfo.lastDays"
                    :key="day"
                    >
                    {{ day }}
                    </b-dropdown-item>
                </b-dropdown>
                </b-card-header>

                <b-card-body>

                <!-- apex chart -->
                <vue-apex-charts
                    type="radialBar"
                    height="325"
                    :options="productOrdersRadialBar.chartOptions"
                    :series="productOrdersRadialBar.series"
                />

                <!-- chart info -->
                <div
                    v-for="(data,key,index) in chartInfo.chartInfo"
                    :key="key"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartInfo.chartInfo).length - 1 ? '':'mb-1'"
                >
                    <div class="series-info d-flex align-items-center">
                    <feather-icon
                        icon="CircleIcon"
                        size="16"
                        :class="key === 'finished' ? 'text-primary': key==='pending'?'text-warning':'text-danger'"
                    />
                    <span class="font-weight-bold text-capitalize ml-75">{{ key }}</span>
                    </div>

                    <span>{{ data }}</span>
                </div>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="12" md="4">
            <b-card no-body>
                <b-card-header>
                <b-card-title>Customers</b-card-title>
                <b-dropdown
                    text="Last 7 Days"
                    variant="transparent"
                    class="chart-dropdown "
                    right
                    no-caret
                    toggle-class="p-0 mb-25"
                    size="sm"
                >
                    <b-dropdown-item
                    v-for="day in chartData.lastDays"
                    :key="day"
                    >
                    {{ day }}
                    </b-dropdown-item>
                </b-dropdown>
                </b-card-header>

                <b-card-body>

                <!-- apex chart -->
                <vue-apex-charts
                    type="pie"
                    height="290"
                    class="mt-2 mb-1"
                    :options="customersPie.chartOptions"
                    :series="customersPie.series"
                />

                <!-- list group -->
                <div class="pt-25">
                    <div
                    v-for="(data,index) in chartDataCustomer.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartDataCustomer.listData).length - 1 ? '':'mb-1'"
                    >
                    <div class="series-info">
                        <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                        />
                        <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                    </div>
                </div>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="3">
            <statistic-card-with-area-chart
                v-if="subscribersGained.analyticsData"
                icon="UsersIcon"
                color="success"
                :statistic="kFormatter(subscribersGained.analyticsData.subscribers)"
                statistic-title="Subscribers Gained"
                :chart-data="subscribersGained.series"
            />
        </b-col>
        <b-col cols="12" md="3">
            <statistic-card-with-area-chart
                v-if="ordersRecevied.analyticsData"
                icon="BoxIcon"
                color="warning"
                :statistic="kFormatter(ordersRecevied.analyticsData.orders)"
                statistic-title="Orders Received"
                :chart-data="ordersRecevied.series"
            />
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="8">
            <b-card no-body>
                <b-card-header>
                <b-card-title>Revenue</b-card-title>
                <feather-icon
                    icon="SettingsIcon"
                    size="18"
                    class="text-muted cursor-pointer"
                />
                </b-card-header>

                <b-card-body class="pb-0">
                <div class="d-flex justify-content-start mb-3">
                    <div class="mr-2">
                    <b-card-text class="mb-50">
                        This Month
                    </b-card-text>
                    <h3 class="font-weight-bolder">
                        <sup class="font-medium-1">$ </sup>
                        <span class="text-success">{{ revenue.thisMonth }}</span>
                    </h3>
                    </div>
                    <div>
                    <b-card-text class="mb-50">
                        Last Month
                    </b-card-text>
                    <h3 class="font-weight-bolder">
                        <sup class="font-medium-1">$ </sup>
                        <span>{{ revenue.lastMonth }}</span>
                    </h3>
                    </div>
                </div>

                <!-- apex chart -->
                <vue-apex-charts
                    type="line"
                    height="240"
                    :options="revenueComparisonLine.chartOptions"
                    :series="revenueComparisonLine.series"
                />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="8">
            <b-card no-body>
                <b-card-header class="align-items-baseline">
                <div>
                    <b-card-title class="mb-25">
                    Sales
                    </b-card-title>
                    <b-card-text class="mb-0">
                    2022 Total Sales: 12.84k
                    </b-card-text>
                </div>
                <feather-icon
                    icon="SettingsIcon"
                    size="18"
                    class="text-muted cursor-pointer"
                />
                </b-card-header>

                <b-card-body class="pb-0">

                <!-- apex chart -->
                <vue-apex-charts
                    type="line"
                    height="240"
                    :options="salesLine.chartOptions"
                    :series="salesLine.series"
                />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="12">
            <b-card no-body>
                <b-card-header>
                <b-card-title>Latest Statistics</b-card-title>
                <!-- datepicker -->
                <div class="d-flex align-items-center">
                    <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    />
                    <flat-pickr
                    v-model="rangePicker"
                    :config="{ mode: 'range'}"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                    />
                </div>
                <!-- datepicker -->
                </b-card-header>

                <!-- chart -->
                <b-card-body>
                <chartjs-component-bar-chart
                    :height="400"
                    :data="chartjsData.latestBarChart.data"
                    :options="chartjsData.latestBarChart.options"
                />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="12">
            <b-card no-body>
                <b-card-header>
                <div>
                    <b-card-title>Horizontal Bar Chart</b-card-title>
                </div>
                <!-- datepicker -->
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center mr-2">
                        <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                        <span>Point One</span>
                        </div>
                        <div class="d-flex align-items-center">
                        <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                        <span>Point Two</span>
                    </div>
                </div>
                <!-- datepicker -->
                </b-card-header>

                <!-- chart -->
                <b-card-body>
                    <chartjs-component-horizontal-bar-chart
                        :height="400"
                        :data="chartjsData.horizontalBarChart.data"
                        :options="chartjsData.horizontalBarChart.options"
                    />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BCardText, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem, BProgress
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import componentsChartData from './componentsChartData'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import ChartjsComponentHorizontalBarChart from './charts-components/ChartjsComponentHorizontalBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCardCode,
    VueApexCharts,
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard, 
    BCardHeader, 
    BCardTitle, 
    BCardBody, 
    BCardText, 
    BDropdown, 
    BDropdownItem,
    BProgress,
    StatisticCardWithAreaChart,
    flatPickr,
    ChartjsComponentBarChart,
    ChartjsComponentHorizontalBarChart,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartjsData,
      rangePicker: ['2022-05-01', '2022-05-10'],
      subscribersGained: {},
      ordersRecevied: {},
      componentsChartData,
      statisticsRadar: {
        chartOptions: {
          chart: {
            height: 300,
            type: 'radar',
            dropShadow: {
              enabled: true,
              blur: 8,
              left: 1,
              top: 1,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
            offsetY: 5,
          },
          stroke: {
            width: 0,
          },
          dataLabels: {
            background: {
              foreColor: ['#ebe9f1'],
            },
          },
          legend: { show: false },
          colors: [$themeColors.primary, $themeColors.danger],
          plotOptions: {
            radar: {
              polygons: {
                strokeColors: ['#ebe9f1', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent'],
                connectorColors: 'transparent',
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: [$themeColors.primary, $themeColors.danger],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
          markers: {
            size: 0,
          },
          yaxis: {
            show: false,
          },
          grid: {
            show: false,
            padding: {
              bottom: -27,
            },
          },
        },
      },
      revenue_report: {},
      revenueReport: {
        series: [
          {
            name: 'Earning',
            data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
          },
          {
            name: 'Expense',
            data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            labels: {
              style: {
                colors: '#5b5b5b',
                fontSize: '0.86rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#e51a4b', '#f08319'],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#5b5b5b',
                fontSize: '0.86rem',
              },
            },
          },
        },
      },

      // budget chart
      budgetChart: {
        series: [
          {
            data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
          },
          {
            data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
          },
        ],
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: ['#f08319', '#c5c5c5'],
          tooltip: {
            enabled: false,
          },
        },
      },
      trackerData: {},
      supportTrackerRadialBar: {
        series: [83],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#000',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#000',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.danger],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Completed Tickets'],
        },
      },
      avgData: {},
      salesBar: {
        series: [
          {
            name: 'Sessions',
            data: [75, 125, 225, 175, 125, 75, 25],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          colors: [
            '#ebf0f7',
            '#ebf0f7',
            $themeColors.danger,
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              endingShape: 'rounded',
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: 'numeric',
          },
        },
      },
      chartData: {},
      sessionsByDeviceDonut: {
        series: [58.6, 34.9, 6.5],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Desktop', 'Mobile', 'Tablet'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: ['#5da773', '#f08319', '#ea5455'],
        },
      },
      chartInfo: {},
      productOrdersRadialBar: {
        series: [70, 52, 26],
        chartOptions: {
          labels: ['Finished', 'Pending', 'Rejected'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: 'Total',
                  fontSize: '1.286rem',
                  colors: '#5e5873',
                  fontWeight: '500',

                  formatter() {
                    return 42459
                  },
                },
              },
            },
          },
          colors: ['#5da773', '#f08319', '#ea5455'],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
      chartDataCustomer: {},
      customersPie: {
        series: [690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['New', 'Returning', 'Referrals'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: ['#5da773', '#f08319', '#ea5455'],
        },
      },
      revenue: {},
      revenueComparisonLine: {
        series: [
          {
            name: 'This Month',
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600],
          },
          {
            name: 'Last Month',
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#a7d1b4', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#317245', '#a7d1b4'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      salesLine: {
        series: [
          {
            name: 'Sales',
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#fdead7'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#f08319'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  created() {
    this.$http.get('/card/card-analytics/revenue-report')
        .then(res => { this.revenue_report = res.data })
    this.$http.get('/card/card-analytics/support-tracker')
        .then(res => { this.trackerData = res.data })
    this.$http.get('/card/card-analytics/avg-sessions')
        .then(res => { this.avgData = res.data })
    this.$http.get('/card/card-analytics/sessions-device')
        .then(res => { this.chartData = res.data })
    this.$http.get('/card/card-analytics/product-orders')
        .then(res => { this.chartInfo = res.data })
    this.$http.get('/card/card-analytics/customers')
        .then(res => { this.chartDataCustomer = res.data })
    this.$http.get('/card/card-statistics/subscribers')
      .then(response => { this.subscribersGained = response.data })
    this.$http.get('/card/card-statistics/orders')
      .then(response => { this.ordersRecevied = response.data })
    this.$http.get('/card/card-analytics/revenue')
        .then(res => { this.revenue = res.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>