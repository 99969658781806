/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      
      { title: 'Alerts', route: { name: 'components' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      { title: 'Graphics', route: { name: 'components' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Labels', route: { name: 'components' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Button', route: { name: 'components' }, icon: 'BoldIcon', isBookmarked: false },
      { title: 'Components', route: { name: 'components' }, icon: '', isBookmarked: true },
      { title: 'Forms', route: { name: 'components' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'Dashboard', route: { name: 'dashboard' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'FAQ', route: { name: 'pages-faq' }, icon: 'HelpCircleIcon', isBookmarked: false },
      { title: 'Popover', route: { name: 'components' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Progress', route: { name: 'components' }, icon: 'ChevronsRightIcon', isBookmarked: false },
      { title: 'Statistics Cards', route: { name: 'components' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'My Account', route: { name: 'apps-users-edit', params: { id: 1 } }, icon: 'UserIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Joe's CV",
        from: 'Stacy Watson',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.7 mb',
      },
      {
        file_name: 'Passport Image',
        from: 'Ben Sinitiere',
        icon: require('@/assets/images/icons/jpg.png'),
        size: ' 52 kb',
      },
      {
        file_name: 'Questions',
        from: 'Charleen Patti',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '1.5 gb',
      },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: '',
        name: 'Rena Brant',
        email: 'nephrod@preany.co.uk',
        time: '21/05/2019',
      },
      {
        img: '',
        name: 'Mariano Packard',
        email: 'seek@sparaxis.org',
        time: '14/01/2018',
      },
      {
        img: '',
        name: 'Risa Montufar',
        email: 'vagary@unblist.org',
        time: '10/08/2019',
      },
    ],
  },
}
/* eslint-enable */