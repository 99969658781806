<template>
  <b-card-code title="Forms">
    <b-row>
      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Default"
        >
          <b-form-input
            placeholder="John"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
            label="Error"
        >
            <b-form-input
            id="input-invalid"
            :state="false"
            placeholder="You must fill this information."
            />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
            label="Success"
        >
            <b-form-input
            id="input-invalid"
            :state="true"
            placeholder="Success state."
            />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
          label="Focus"
        >
          <b-form-input
            placeholder="John"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
            label="Disabled"
        >
            <b-form-input
            placeholder="Disabled field"
            disabled
            />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
              label="Country"
              label-for="country"
            >
              <v-select
                id="country"
                placeholder="Select Country"
                :options="countryName"
                :selectable="option => ! option.value.includes('select_country')"
                label="text"
              />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
            label="Upload file default"
        >
            <b-form-file
                v-model="file"
                placeholder="Upload File"
            />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
            label="Multi option/Tags"
        >
            <v-select
                id="multitag"
                multiple
                placeholder="Select Options"
                :options="multitagName"
                :selectable="option => ! option.value.includes('select_multitag')"
                label="text"
            />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
            label="Upload file default"
        >
            <b-form-file
                v-model="file"
                placeholder="Upload File"
            />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
            label="Textarea"
        >
            <b-form-textarea
                id="textarea-default"
                placeholder="Descrição teste"
                rows="4"
            />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
          label="Datapicker"
        >
          <b-form-datepicker
            v-model="value"
            class="mb-1"
            selected-variant="danger"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
            label="Select Dropdown Default"
        >
            <v-select
                id="country"
                placeholder="Select Country"
                :options="countryName"
                :selectable="option => ! option.value.includes('select_country')"
                label="text"
              />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
              label="Select Dropdown tabs"
            >
              <v-select
                id="country"
                placeholder="Select Country"
                :options="countryName"
                :selectable="option => ! option.value.includes('select_country')"
                label="text"
              />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col
        md="4"
        xl="2"
      >
        <h6>Checkbox</h6>
        <b-form-checkbox
            class="custom-control-danger mb-1"
        >
            Unchecked
        </b-form-checkbox>
        <b-form-checkbox
            disabled
            class="custom-control-danger mb-1"
        >
            Disabled
        </b-form-checkbox>
        <b-form-checkbox
            v-model="selectedCheck"
            value="A"
            class="custom-control-danger"
        >
            Selected
        </b-form-checkbox>
      </b-col>
      <b-col
        md="4"
        xl="2"
      >
        <h6>Switches</h6>
        <div class="mb-1 d-flex align-items-center">
            <b-form-checkbox
                class="custom-control-danger"
                name="check-button"
                switch
            >
                <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
                </span>
            </b-form-checkbox>
            <b-card-text class="mb-0">
                Off
            </b-card-text>
        </div>
        <div class="mb-1 d-flex align-items-center">
            <b-form-checkbox
                checked="true"
                class="custom-control-danger"
                name="check-button"
                switch
            >
                <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
                </span>
            </b-form-checkbox>
            <b-card-text class="mb-0">
                On
            </b-card-text>
        </div>
        <div class="d-flex align-items-center">
            <b-form-checkbox
                disabled
                class="custom-control-danger"
                name="check-button"
                switch
            >
                <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
                </span>
            </b-form-checkbox>
            <b-card-text class="mb-0">
                Disabled
            </b-card-text>
        </div>
      </b-col>

      <b-col
        md="4"
        xl="8"
      >
        <h6>Number Inputs</h6>
        <div class="mb-1">
            <label
            class="mr-2"
            >Large</label>
            <b-form-spinbutton
            class="mr-4"
            value="50"
            size="lg"
            inline
            />
            <label
            class="mr-2"
            >Hover</label>
            <b-form-spinbutton
            value="50"
            inline
            />
        </div>
        <div class="mb-1">
            <label
            class="mr-2"
            >Default</label>
            <b-form-spinbutton
            value="50"
            inline
            />
        </div>
        <div>
            <label
            class="mr-2"
            >Small</label>
            <b-form-spinbutton
            value="50"
            size="sm"
            inline
            />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col
        md="4"
        xl="2"
      >
        <h6>Radio buttons</h6>
        <b-form-radio
            class="custom-control-danger mb-1"
        >
            Unchecked
        </b-form-radio>
        <b-form-radio
            disabled
            class="custom-control-danger mb-1"
        >
            Disabled
        </b-form-radio>
        <b-form-radio
            v-model="SelectedRadio"
            value="A"
            class="custom-control-danger"
        >
            Selected
        </b-form-radio>
      </b-col>
      <b-col
        md="4"
        xl="10"
      >
        <h6>Custom Option Radios With Icons</h6>
        
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mr-2"
        md="4"
        xl="6"
      >
        <h6 class="mb-2">Sliders</h6>
        <b-card-text>Default</b-card-text>
        <vue-slider
          v-model="value"
          :direction="direction"
          class="mb-2 vue-slider-danger"
        />
        <b-card-text>Handles</b-card-text>
        <vue-slider
          v-model="value3"
          :adsorb="true"
          :marks="marks"
          :direction="direction"
          class="mb-2 vue-slider-danger"
        />
      </b-col>
      <b-col
        md="4"
        xl="4"
      >
        <div class="d-flex">
          <b-card-text>Vertical</b-card-text>
          <vue-slider
            v-model="value7"
            direction="btt"
            :height="200"
            :min-range="0"
            :max-range="100"
            class="mt-2 vue-slider-danger"
            :tooltip="'always'"
          />
        </div>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormFile, BFormTextarea, BFormDatepicker, 
  BFormCheckbox, BFormCheckboxGroup, BFormSpinbutton, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import store from '@/store/index'


export default {
  components: {
    BCardCode,
    VueSlider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSpinbutton,
    BFormRadio,
    vSelect,
  },
  data() {
    return {
      value: 10,
      value3: [10, 40],
      value7: [25, 75],
      marks: [0, 100],
      dir: 'ltr',
      SelectedRadio: 'A',
      selectedCheck: 'A',
      countryName: [
        // { value: 'select_country', text: 'Select Country' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      multitagName: [
        // { value: 'select_country', text: 'Select Country' },
        { value: 'Option_01', text: 'Option 01' },
        { value: 'Option_02', text: 'Option 02' },
        { value: 'Option_03', text: 'Option 03' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
}
</script>