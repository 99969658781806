<template>
  <b-card-code
    title="Other"
  >
    <h6>Some cards</h6>
    <b-row class="mb-2">
        <b-col cols="12" md="2">
            <statistic-card-vertical
                icon="EyeIcon"
                statistic="36.9k"
                statistic-title="Views"
                color="danger"
            />
        </b-col>
        <b-col cols="12" md="2">
            <statistic-card-vertical
                icon="EyeIcon"
                statistic="36.9k"
                statistic-title="Views"
                color="danger"
            />
        </b-col>
        <b-col cols="12" md="2">
            <statistic-card-vertical
                icon="EyeIcon"
                statistic="36.9k"
                statistic-title="Views"
                color="danger"
            />
        </b-col>
        <b-col cols="12" md="2">
            <statistic-card-vertical
                icon="EyeIcon"
                statistic="36.9k"
                statistic-title="Views"
                color="success"
            />
        </b-col>
        <b-col cols="12" md="2">
            <statistic-card-vertical
                icon="EyeIcon"
                statistic="36.9k"
                statistic-title="Views"
                color="success"
            />
        </b-col>
        <b-col cols="12" md="2">
            <statistic-card-vertical
                icon="EyeIcon"
                statistic="36.9k"
                statistic-title="Views"
                color="success"
            />
        </b-col>
    </b-row>
    <h6>Navbar search options</h6>
    <b-row class="mb-2">
        <b-col cols="12" md="12">
            <component-search />
        </b-col>
    </b-row>
    <h6>Tosts / Toastr / Screen Alerts</h6>
    <b-row>
        <b-col cols="12" md="5">
            <b-toast
                id="example-toast3"
                v-model="visible"
                static
                no-auto-hide
            >
                <template #toast-title>
                    <div class="d-flex flex-grow-1 align-items-center mr-1">
                    <b-img
                        :src="require('@/assets/images/logo/logo.png')"
                        class="mr-1"
                        height="18"
                        width="25"
                        alt="Toast image"
                    />
                    <strong class="mr-auto">Toast Basic</strong>
                    <small class="text-muted">11 mins ago</small>
                    </div>
                </template>
                <span>Fruitcake chocolate bar tootsie roll gummies gummies jelly beans cake.</span>
            </b-toast>
        </b-col>
        <b-col cols="12" md="5">
            <b-toast
                id="example-toast3"
                v-model="visible"
                static
                no-auto-hide
            >
                <template #toast-title>
                    <div class="d-flex flex-grow-1 align-items-center mr-1">
                    <strong class="mr-auto">Toast Primary</strong>
                    </div>
                </template>
            </b-toast>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="5">
            <b-toast
                id="example-toast3"
                v-model="visible"
                static
                no-auto-hide
            >
                <template #toast-title>
                    <div class="d-flex flex-grow-1 align-items-center mr-1">
                    <feather-icon
                        icon="BellIcon"
                        size="20"
                        class="mr-1"
                    />
                    <strong class="mr-auto">Toast Primary</strong>
                    <small class="text-muted">11 mins ago</small>
                    </div>
                </template>
                <span>Fruitcake chocolate bar tootsie roll gummies gummies jelly beans cake.</span>
            </b-toast>
        </b-col>
        <b-col cols="12" md="5">
            <b-toast
                id="example-toast3"
                v-model="visible"
                static
                no-auto-hide
            >
                <template #toast-title>
                    <div class="d-flex flex-grow-1 align-items-center mr-1">
                    <feather-icon
                    icon="BellIcon"
                    size="20"
                    class="mr-1"
                    />
                    <strong class="mr-auto">Toast Primary</strong>
                    </div>
                </template>
            </b-toast>
        </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BRow, BCol, BNavbar, BToast, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ComponentSearch from './search/ComponentSearch.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BNavbar,
    BToast,
    BImg,
    StatisticCardVertical,
    ComponentSearch,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      visible: true,
    }
  },
}
</script>
<style lang="scss" scoped>
.translucent-wrap {
  height: 110px;
  position: relative;
}
</style>