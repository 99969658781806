<template>
  <b-card-code
    title="Labels"
  >
    <b-row>
        <b-col cols="12" md="6">
            <div class="content-inline-spacing">
                <b-badge variant="light-primary">
                    Default
                </b-badge>
                <b-badge variant="light-info">
                    Info
                </b-badge>
                <b-badge variant="light-warning">
                    Warning
                </b-badge>
                <b-badge variant="light-success">
                    Success
                </b-badge>
                <b-badge variant="light-danger">
                    Error
                </b-badge>
            </div>
        </b-col>
        <b-col cols="12" md="6">
            <div class="content-inline-spacing">
                <b-badge variant="primary">
                    Default
                </b-badge>
                <b-badge variant="info">
                    Info
                </b-badge>
                <b-badge variant="warning">
                    Warning
                </b-badge>
                <b-badge variant="success">
                    Success
                </b-badge>
                <b-badge variant="danger">
                    Error
                </b-badge>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" md="6">
            <div class="content-inline-spacing">
                <b-badge variant="light-primary">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Default
                </b-badge>
                <b-badge variant="light-info">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Info
                </b-badge>
                <b-badge variant="light-warning">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Warning
                </b-badge>
                <b-badge variant="light-success">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Success
                </b-badge>
                <b-badge variant="light-danger">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Error
                </b-badge>
            </div>
        </b-col>
        <b-col cols="12" md="6">
            <div class="content-inline-spacing">
                <b-badge variant="primary">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Default
                </b-badge>
                <b-badge variant="info">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Info
                </b-badge>
                <b-badge variant="warning">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Warning
                </b-badge>
                <b-badge variant="success">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Success
                </b-badge>
                <b-badge variant="danger">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-25"
                    />
                    Error
                </b-badge>
            </div>
        </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BBadge, BCardText, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BCardText,
    BBadge,
    BRow,
    BCol,
  },
}
</script>