<template>
  <div>
    <b-card-code
      title="Alert messages"
    >
      <div class="content-spacing-0">
        <b-alert
        show
        variant="info"
        dismissible
        >
        <div class="alert-body">
            <feather-icon
            class="mr-25"
            icon="StarIcon"
            />
            <span class="ml-25"><b>Info!</b> This is a primary alert — check it out!</span>
        </div>
        </b-alert>
        
        <b-alert
        show
        variant="secondary"
        dismissible
        >
        <div class="alert-body">
            <feather-icon
            class="mr-25"
            icon="StarIcon"
            />
            <span class="ml-25"><b>Secondary!</b> This is a primary alert — check it out!</span>
        </div>
        </b-alert>
        
        <b-alert
        show
        variant="success"
        dismissible
        >
        <div class="alert-body">
            <feather-icon
            class="mr-25"
            icon="StarIcon"
            />
            <span class="ml-25"><b>Success!</b> This is a primary alert — check it out!</span>
        </div>
        </b-alert>

        <b-alert
        show
        variant="warning"
        dismissible
        >
        <div class="alert-body">
            <feather-icon
            class="mr-25"
            icon="StarIcon"
            />
            <span class="ml-25"><b>Warning!</b> This is a primary alert — check it out!</span>
        </div>
        </b-alert>

        <b-alert
        show
        variant="danger"
        dismissible
        >
        <div class="alert-body">
            <feather-icon
            class="mr-25"
            icon="StarIcon"
            />
            <span class="ml-25"><b>Danger!</b> This is a primary alert — check it out!</span>
        </div>
        </b-alert>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BAlert, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BAlert,
    BCardText,
  },
}
</script>