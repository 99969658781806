<template>
    <b-card-code title="Popovers">
        <div class="content-inline-spacing">
            <b-button
                id="popover-button-top"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
            >
                Popover on top
            </b-button>
            <b-popover
            target="popover-button-top"
            variant="primary"
            triggers="hover"
            placement="top"
            >
                <template #title>
                    <span>Popover top</span>
                </template>
                <span>This is a very beautiful popover, show some love.</span>
                
                <div class="d-flex justify-content-between mt-1">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                    >
                        Skip
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="dark"
                    >
                        Read More
                    </b-button>
                </div>
            </b-popover>
            <b-button
            id="popover-button-bottom"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            >
            Popover on bottom
            </b-button>
            <b-popover
            target="popover-button-bottom"
            variant="primary"
            triggers="hover"
            placement="bottom"
            >
                <template #title>
                    <span>Popover bottom</span>
                </template>
                <span>This is a very beautiful popover, show some love.</span>
                
                <div class="d-flex justify-content-between mt-1">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                    >
                        Skip
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="dark"
                    >
                        Read More
                    </b-button>
                </div>
            </b-popover>
            <b-button
            id="popover-button-left"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            >
            Popover on left
            </b-button>
            <b-popover
            target="popover-button-left"
            variant="primary"
            triggers="hover"
            placement="left"
            >
                <template #title>
                    <span>Popover left</span>
                </template>
                <span>This is a very beautiful popover, show some love.</span>
                
                <div class="d-flex justify-content-between mt-1">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                    >
                        Skip
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="dark"
                    >
                        Read More
                    </b-button>
                </div>
            </b-popover>
            <b-button
            id="popover-button-right"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            >
            Popover on right
            </b-button>
            <b-popover
            target="popover-button-right"
            variant="primary"
            triggers="hover"
            placement="right"
            >
                <template #title>
                    <span>Popover right</span>
                </template>
                <span>This is a very beautiful popover, show some love.</span>
                
                <div class="d-flex justify-content-between mt-1">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                    >
                        Skip
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="dark"
                    >
                        Read More
                    </b-button>
                </div>
            </b-popover>
        </div>
    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, VBPopover, BPopover, BCardText, BRow, BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BPopover,
    BRow, 
    BCol,
    BButton,
    BCardText,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
}
</script>