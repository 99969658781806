<template>
  <b-card-code title="Tooltips">
    <b-row>
        <b-col cols="12" md="3">
            <b-button
                class="btn-sm"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.bottom="'Tooltip on bottom!'"
                variant="outline-primary"
            >
                Tooltip on bottom
            </b-button>
        </b-col>
        <b-col cols="12" md="3">
            <b-button
                class="btn-sm"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Tooltip on top!'"
                variant="outline-primary"
            >
                Tooltip on top
            </b-button>
        </b-col>
        <b-col cols="12" md="3">
            <b-button
                class="btn-sm"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.left="'Tooltip on left!'"
                variant="outline-primary"
            >
                Tooltip on left
            </b-button>
        </b-col>
        <b-col cols="12" md="3">
            <b-button
                class="btn-sm"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.right="'Tooltip on right!'"
                variant="outline-primary"
            >
                Tooltip on right
            </b-button>
        </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, VBTooltip, BCardText, BRow, BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BButton,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>