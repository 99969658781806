<template>
  <b-card-code title="Progress bar">
    <div class="content-vertical-spacing">
        <div>
            <label>Default</label>
            <b-progress
                value="25"
                max="100"
                class="progress-bar-danger"
            />
            <b-progress
                class="custom-progress mt-1 progress-bar-danger"
                value="50"
                max="100"
            />
        </div>
        <div>
            <label>Labeled Progress</label>
            <b-progress
                max="100"
                class="progress-bar-danger"
            >
                <b-progress-bar
                value="75"
                :label="`${((75 / 100) * 100).toFixed(2)}%`"
                variant="danger"
                />
            </b-progress>
        </div>
        <div>
            <label>Striped</label>
            <b-progress
                value="75"
                max="100"
                striped
                variant="success"
                class="progress-bar-success"
            />
        </div>
        <div>
            <label>Multiple Bars</label>
            <b-progress max="100">
                <b-progress-bar
                variant="danger"
                value="25"
                :label="`${((25 / 100) * 100).toFixed(2)}%`"
                show-progress
                />
                <b-progress-bar
                variant="warning"
                value="75"
                :label="`${((75 / 100) * 100).toFixed(2)}%`"
                show-progress
                />
                <b-progress-bar
                variant="danger"
                value="25"
                :label="`${((25 / 100) * 100).toFixed(2)}%`"
                show-progress
                />
            </b-progress>
        </div>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BProgress, BCardText, BProgressBar } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BProgress,
    BCardText,
    BProgressBar
  },
}
</script>