<template>
  <div class="navbar-container d-flex align-items-center">

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <search-bar-component />
    </div>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} 
from 'bootstrap-vue'
import SearchBarComponent from './SearchBarComponent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    SearchBarComponent,
  },
  props: {
    toggleSearchActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
