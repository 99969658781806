<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <type-buttons />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <alert-messages />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <type-labels />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <tooltips />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <progress-bar />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <popovers/>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <forms-inputs/>
      </b-col>
    </b-row>
    
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <type-graphics :data="data.salesChart"/>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <type-others />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { kFormatter } from '@core/utils/filter'
import { BRow, BCol } from 'bootstrap-vue'
import TypeButtons from './TypeButtons.vue'
import AlertMessages from './AlertMessages.vue'
import TypeLabels from './TypeLabels.vue'
import Tooltips from './Tooltips.vue'
import ProgressBar from './ProgressBar.vue'
import Popovers from './Popovers.vue'
import FormsInputs from './formsInputs.vue'
import TypeGraphics from './TypeGraphics.vue'
import TypeOthers from './typeOthers.vue'

export default {
  components: {
    BRow,
    BCol,
    TypeButtons,
    AlertMessages,
    TypeLabels,
    Tooltips,
    ProgressBar,
    Popovers,
    FormsInputs,
    TypeGraphics,
    TypeOthers,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>