<template>
    <b-card-code>
        <h2 class="text-dark">
            Buttons
        </h2>
        <b-row class="mb-1">
            <b-col cols="12" md="4">
                <b-card-text class="mb-2">
                    <h6>Default</h6>
                </b-card-text>
            </b-col>
            <b-col cols="12" md="8">
                <b-card-text class="mb-2">
                    <h6>Default + Icon</h6>
                </b-card-text>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="d-flex" cols="12" md="4">
                <span class="col-md-4">
                    Default<br/>
                    Outline
                </span>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline"
                    class="custom-default-btn"
                >
                    Default Outline
                </b-button>
            </b-col>
            <b-col class="d-flex" cols="12" md="8">
                <span class="col-md-2">
                    Default<br/>
                    Outline
                </span>
                <b-button
                    class="mr-2 custom-default-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline"
                >
                    <span class="align-middle mr-50">Default Outline</span>
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline"
                    class="custom-default-btn"
                >
                    <feather-icon
                        icon="ArrowLeftIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Default Outline</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="d-flex" cols="12" md="4">
                <span class="col-md-4">
                    Default
                </span>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="dark"
                >
                    Default
                </b-button>
            </b-col>
            <b-col class="d-flex" cols="12" md="8">
                <span class="col-md-2">
                    Default
                </span>
                <b-button
                    class="mr-2"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="dark"
                >
                    <span class="align-middle mr-50">Default</span>
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="dark"
                >
                    <feather-icon
                        icon="ArrowLeftIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Default</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="d-flex" cols="12" md="4">
                <span class="col-md-4">
                    Hover
                </span>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                >
                    Hover
                </b-button>
            </b-col>
            <b-col class="d-flex" cols="12" md="8">
                <span class="col-md-2">
                    Hover
                </span>
                <b-button
                    class="mr-2"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                >
                    <span class="align-middle mr-50">Hover</span>
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                >
                    <feather-icon
                        icon="ArrowLeftIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Hover</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="d-flex" cols="12" md="4">
                <span class="col-md-4">
                    Disabled
                </span>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    disabled
                >
                    Disabled
                </b-button>
            </b-col>
            <b-col class="d-flex" cols="12" md="8">
                <span class="col-md-2">
                    Disabled
                </span>
                <b-button
                    class="mr-2"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    disabled
                >
                    <span class="align-middle mr-50">Disabled</span>
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    disabled
                >
                    <feather-icon
                        icon="ArrowLeftIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Disabled</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="d-flex" cols="12" md="4">
                <span class="col-md-4">
                    Default<br/>
                    sm
                </span>
                <b-button
                    class="btn-sm"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="dark"
                >
                    Default sm
                </b-button>
            </b-col>
            <b-col class="d-flex" cols="12" md="8">
                <span class="col-md-2">
                    Shadow<br/>
                    example
                </span>
                <b-button
                    class="mr-2 notification-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    
                >
                    <feather-icon
                        icon="BellIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Notification</span>
                </b-button>
            </b-col>
        </b-row>
    </b-card-code>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCardCode,
  },
  directives: {
    Ripple,
  },
}
</script>